// amazon-cognito-identity-js is contained in @kaluza-tech/flex-user-auth
// eslint-disable-next-line import/no-extraneous-dependencies
import { CognitoUser } from 'amazon-cognito-identity-js';
import cookies from 'js-cookie';
import config from '../config';
import {
  authCookieName,
  rolesCookieName,
  permissionsCookieName,
  providerCookieName,
  organisationsCookieName
} from '../constants/cookies';

const cookieExpiryDays = 30;

export interface User {
  id: string;
  roles: string[];
  permissions: string[];
  organisations: {
    name: string;
  }[];
}

const setCookie = (name: string, value: string, expiry?: Date) => {
  const thirtyDaysAway = new Date();
  thirtyDaysAway.setDate(thirtyDaysAway.getDate() + cookieExpiryDays);
  const expires = expiry ?? thirtyDaysAway;

  cookies.set(name, value, { expires, domain: `.${window.location.hostname}`, secure: true });
};

export const saveUserCookies = async (cognitoUser: CognitoUser): Promise<string[]> => {
  const session = cognitoUser.getSignInUserSession();

  if (session === null) {
    throw new Error('No signIn user session exists');
  }

  const idToken = session?.getIdToken();
  const token = idToken.getJwtToken();

  const provider = 'COGNITO';

  const response = await fetch(`${config.cognito.userManagementURL}/users/token`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: token,
      'x-provider': provider
    },
    body: JSON.stringify({
      provider,
      app: 'FLEX',
      token
    })
  });

  const user = await response.json();

  setCookie(authCookieName, token, new Date(idToken.getExpiration() * 1000));
  setCookie(providerCookieName, provider);
  setCookie(rolesCookieName, user.roles);
  setCookie(permissionsCookieName, user.permissions);
  setCookie(organisationsCookieName, user.organisations);

  return user.permissions;
};
