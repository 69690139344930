import { logoutQuery, redirectToQuery } from 'constants/query-params';
import config from '../config';

const absoluteUrl = new RegExp('^([a-z]+://|//)', 'i');

export const getRedirectPath = (search = window.location.search) =>
  new URLSearchParams(search).get(redirectToQuery);

export const isValidPath = (url) =>
  url.indexOf('/') === 0 && !url.includes('.') && !absoluteUrl.test(url);

export const isLogoutRequested = () => {
  const search = window.location.search.toLowerCase();
  return new URLSearchParams(search).has(logoutQuery);
};

export const createRedirectUrl = (permissions, { search } = { search: window.location.search }) => {
  if (!permissions || !Array.isArray(permissions) || permissions.length === 0) {
    return null;
  }

  const baseUrl = config.redirectBaseUrl || `https://${window.location.host}`;

  const redirectToInQuery = getRedirectPath(search);

  if (redirectToInQuery && isValidPath(redirectToInQuery)) {
    return `${baseUrl}${redirectToInQuery}`;
  }

  const redirectToInLocalStorage = localStorage.getItem(redirectToQuery);

  if (redirectToInLocalStorage) {
    return `${baseUrl}${redirectToInLocalStorage}`;
  }

  if (permissions.includes('read:operations')) {
    return `${baseUrl}/ops`;
  }

  if (permissions.includes('read:marketing')) {
    return `${baseUrl}/supplier/marketing`;
  }

  if (permissions.includes('read:network')) {
    return `${baseUrl}/supplier/network`;
  }

  if (permissions.includes('read:price-response')) {
    return `${baseUrl}/supplier/price-response`;
  }

  if (
    permissions.includes('installer:customer:read') ||
    permissions.includes('installer:register-customer')
  ) {
    return `${baseUrl}/install`;
  }

  // Catchall redirect url
  return `${baseUrl}/ops`;
};

export const saveRedirectPathToLocalStorage = () => {
  const redirectPath = getRedirectPath();

  if (redirectPath && isValidPath(redirectPath)) {
    localStorage.setItem(redirectToQuery, redirectPath);
  }
};
