import React, { useEffect, useState } from 'react';
import { Auth } from '@kaluza-tech/flex-user-auth';
import { saveUserCookies } from '../api/user-management';
import { PostLoginRedirect } from '../components/post-login-redirect';

export const CallbackPage = () => {
  const [permissions, setPermissions] = useState(null);

  useEffect(() => {
    Auth.addListener((hubCapsule) => {
      const { payload } = hubCapsule;
      const { event, data } = payload;

      // Event raised if hosted login is successful
      if (event === 'signIn') {
        saveUserCookies(data).then((userPermissions) => {
          setPermissions(userPermissions);
        });
      }

      // Event raised if the user has been disabled,
      // but still has a refresh token
      if (event === 'signIn_failure') {
        /**
         * If a user has been disabled the error message will contain the text "invalid_grant"
         * indicating the user cannot access the system and any attempts at issuing a new token
         * should cease
         *
         * If a user deletes their cookies but is still enabled the error message will
         * contain the text "invalid_request". If this is the case the user can be redirected
         * to the login page, which will result in the user being issue a new token.
         */
        if (data.toString().includes('invalid_grant')) {
          setPermissions([]);
        } else {
          Auth.redirectToLoginPage();
        }
      }
    });
  }, []);

  if (permissions) {
    return <PostLoginRedirect permissions={permissions} />;
  }

  return <div>Checking your credentials...</div>;
};
