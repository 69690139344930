import React, { useState, useEffect } from 'react';
import './App.scss';
import { KaluzaThemeProvider } from '@kaluza-tech/component-library';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Auth } from '@kaluza-tech/flex-user-auth';
import config from 'config';
import cookies from 'utils/cookie';
import { isLogoutRequested } from 'utils/url';
import { PostLoginRedirect } from 'components/post-login-redirect';
import { LogoutPage } from './pages/logout';
import { LoginRedirectPage } from './pages/login-redirect';
import { CallbackPage } from './pages/callback';

Auth.init(config.cognito);

const RootPage = () => {
  const [permissions, setPermissions] = useState(null);
  const [isUnauthorized, setIsUnauthorized] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const { permissions: fetchedPermissions } = await Auth.getCurrentUser();
        setPermissions(fetchedPermissions);
      } catch (error) {
        setIsUnauthorized(true);
      }
    })();
  }, []);

  if (isLogoutRequested()) {
    return <LogoutPage />;
  }

  if (isUnauthorized) {
    return <LoginRedirectPage />;
  }

  return <PostLoginRedirect permissions={permissions} />;
};

const App = () => {
  // The legacy login flow stores cookies on the top-level domain
  // We need to remove them to prevent them conflicting
  cookies.removeAuthCookies('kaluza.com');

  return (
    <KaluzaThemeProvider>
      <div className="login-container">
        <div className="left-wrapper">
          <div>
            <div className="company-id">
              <a href="/">
                <img src="/assets/kaluza-logo.svg" alt="Kaluza logo" width="60px" height="60px" />
              </a>
              <div className="company-id-text">
                <div>KALUZA</div>
                <div>INTELLIGENT PLATFORM</div>
              </div>
            </div>
            <div className="tagline">
              <BrowserRouter>
                <Switch>
                  <Route path="/" exact>
                    <RootPage />
                  </Route>
                  <Route path="/callback" exact>
                    <CallbackPage />
                  </Route>
                  <Route path="/logout" exact>
                    <LogoutPage />
                  </Route>
                </Switch>
              </BrowserRouter>
            </div>
          </div>
        </div>
        <div className="background-image" />
      </div>
    </KaluzaThemeProvider>
  );
};

export default App;
