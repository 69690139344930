import React, { useEffect } from 'react';
import cookies from 'utils/cookie';
import { Auth } from '@kaluza-tech/flex-user-auth';

export const LogoutPage = () => {
  useEffect(() => {
    cookies.removeAuthCookies();

    Auth.logout();
  }, []);

  return <div className="flex">Logging out...</div>;
};
