module.exports = {
  redirectBaseUrl: '',
  cognito: {
    platformSubdomain: 'dev',
    userManagementURL: 'https://user-management.dev.gridsvc.net/api',
    loginRedirectDestination: 'https://platform.dev.kaluza.com/callback',
    logoutRedirectDestination: 'https://platform.dev.kaluza.com/'
  },
  cookies: {
    domain: 'platform.dev.kaluza.com'
  }
};
