module.exports = {
  redirectBaseUrl: 'https://local.platform.dev.kaluza.com:3000',
  cognito: {
    userPoolId: 'eu-west-2_qxHEwuKYb',
    userPoolWebClientId: '6u0tf1u2eugqqndqauakm9qfn2',
    platformSubdomain: 'dev',
    cognitoSubdomain: 'platform-dev-kaluza',
    userManagementURL: 'https://user-management.dev.gridsvc.net/api',
    loginRedirectDestination: 'https://local.platform.dev.kaluza.com:3001/callback',
    logoutRedirectDestination: 'https://local.platform.dev.kaluza.com:3001/'
  },
  cookies: {
    domain: 'local.platform.dev.kaluza.com'
  }
};
