import Cookies from 'js-cookie';
import {
  authCookieName,
  rolesCookieName,
  providerCookieName,
  permissionsCookieName,
  organisationsCookieName
} from 'constants/cookies';
import config from 'config';

const get = (name) => Cookies.get(name);

const set = (name, value, options) => {
  Cookies.set(name, value, {
    secure: true,
    domain: 'kaluza.com',
    ...options
  });
};

const remove = (name, options) => {
  Cookies.remove(name, options);
};

const removeAuthCookies = (domain = config.cookies.domain) => {
  remove(authCookieName, { domain });
  remove(rolesCookieName, { domain });
  remove(permissionsCookieName, { domain });
  remove(providerCookieName, { domain });
  remove(organisationsCookieName, { domain });
};

/**
 * @description Check if auth and permissions cookies already exist and if they do, return a list of the users' permissions
 */
const getUserPermissions = () => {
  if (!document.cookie) return [];

  const authToken = get(authCookieName);
  const permissions = get(permissionsCookieName);

  if (!authToken || !permissions) {
    return [];
  }

  return permissions;
};

export default {
  get,
  set,
  remove,
  removeAuthCookies,
  getUserPermissions
};
