import React, { useEffect } from 'react';
import { Auth } from '@kaluza-tech/flex-user-auth';
import { saveRedirectPathToLocalStorage } from 'utils/url';

export const LoginRedirectPage = () => {
  useEffect(() => {
    saveRedirectPathToLocalStorage();
    Auth.redirectToLoginPage();
  }, []);

  return <div className="flex">Redirecting to login page...</div>;
};
