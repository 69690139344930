/* eslint-disable @typescript-eslint/no-var-requires */
const merge = require('lodash.merge');
const defaultConfig = require('./default');

let config;
/* eslint-disable global-require */
if (process.env.REACT_APP_HOST_ENV === 'development') {
  config = require('./development');
} else if (process.env.REACT_APP_HOST_ENV === 'staging') {
  config = require('./staging');
} else if (process.env.REACT_APP_HOST_ENV === 'production') {
  config = require('./production');
}
/* eslint-enable global-require */

module.exports = merge(defaultConfig, config);
