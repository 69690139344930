import React from 'react';
import PropTypes from 'prop-types';
import { redirectToQuery } from 'constants/query-params';
import { createRedirectUrl } from '../utils/url';

export const PostLoginRedirect = (props) => {
  const { permissions } = props;

  if (permissions === null) {
    return <div>Checking your credentials...</div>;
  }

  const redirectUrl = createRedirectUrl(permissions);

  if (redirectUrl) {
    localStorage.removeItem(redirectToQuery);
    window.location.href = redirectUrl;

    return <div>Redirecting to site...</div>;
  }

  return <div>Unauthorized</div>;
};

PostLoginRedirect.defaultProps = {
  permissions: []
};

PostLoginRedirect.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string)
};
